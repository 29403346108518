import bomblogo from './images/bomblogo.png';
import koodostext from './images/koodostext.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="top-wrapper">
          <a href="https://koodos.com">
            <img src={koodostext} className="text-logo" alt="logo" />
          </a>
          <div class="login-buttons-wrapper">
            <button className="login-button">
              <a className="nomarkers" href="https://koodos.com/login">
                <b className="nodec-buttons-text">login</b>
              </a>
            </button>
            <button className="signup-button">
              <a className="nomarkers" href="https://koodos.com/sign-up?invite=16318278032&utm_source=discord-invite&utm_medium=discord&utm_campaign=discord-invite&utm_content=discord-invite">
                <b className="nodec-buttons-text">sign up</b>
              </a></button>
          </div>
        </div>


      </header>
      <body className="landing-body">
        <b className="landing-big-text">your community’s best music</b>
        <b className="landing-big-text">moments surfaced in your</b>
        <b className="landing-big-text">server</b>

        <br></br>
        <br></br>
        <button className="add-bot-button">
          <a className="nomarkers" href="https://discord.com/api/oauth2/authorize?client_id=999015482709508187&permissions=534723946560&scope=bot">
            <b className="nodec-buttons-text">add to discord</b>
          </a>
        </button>
        <br></br>

        <a className="nomarkers" href="https://discord.gg/yjD4gcytJN">
          <b className="join-our-server-text">join our server</b>
        </a>
        <br></br>
        <br></br>
        <br></br>
        <a className="nomarkers" href="https://www.notion.so/koodos/koodos-Discord-bot-FAQ-9d5cbdd329154419b52d49d7103fe11a">
          <b className="faq-text">FAQ</b>
        </a>
        <br></br>
        <br></br>
        <img src={bomblogo} className="bomb-logo" alt="logo" />

      </body>
    </div>
  );
}

export default App;
